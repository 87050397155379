import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
// import { BiTrash } from 'react-icons/bi'
// import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';

// import { RiDiscountPercentFill } from "react-icons/ri";
// import { CiPercent } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
// import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
// import { Store } from 'react-notifications-component';
// import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css/animate.min.css';
import { BiSolidSave } from "react-icons/bi";
// import { RiCheckboxBlankCircleLine } from "react-icons/ri";
// import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
// import ComfirmReportModal from "./ComfirmReportModal.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcOk, FcUp } from "react-icons/fc";
// import { FaMinus } from "react-icons/fa";
// import { FaPlus } from "react-icons/fa";
// import { MdOutlineRadioButtonUnchecked, MdOutlineCheckCircleOutline } from "react-icons/md";
// import { FaCheck } from "react-icons/fa6";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import AddContrAgentsRashodModal from "../ContrAgents/AddContrAgentsRashodModal.js";
import EditContrAgentsRashodModal from "../ContrAgents/EditContrAgentsRashodModal.js";
import EditShopIncasationsModal from "../ShopIncasations/EditShopIncasationsModal.js";
const AvansReportForm = ({ id }) => {
  const { editShopIncasationsModal, setEditShopIncasationsModal, shops, avansReportsForms, setAvansReportsForms, avansReportsFormsModal, setAvansReportsFormsModal, incosations, setIncosations, contragentsPrihods, editCARashod, seteditCARashod, avansReports, setAvansReports, addCARashod, setaddCARashod, contragents, setAdmin, setComfirmReportModal, saleReports, setSaleReports, avansReportModal, setAvansReportModal, saleReportForms, saleReportSteps, setSaleReportSteps, scladShops, reciepts, sclad, setEditShopModal, admins, Notify, admin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [idi, setId] = useState('')
  console.log(avansReports)
  const [edit_id, setEdit_Id] = useState(0)
  const formReports = avansReports.filter(pr => pr.form_id == id)
  const thisForm = avansReportsForms.find(pr => pr.id == id)
  const thisShop = shops.find(pr => pr.id == thisForm.shop_id)
  console.log(formReports)
  const OpnaEditModal = (idd) => {
    setId(idd)
    setEditShopIncasationsModal(idd)
  }
  const OpnaEditPrihoModal = (id) => {
    setEdit_Id(id)
    seteditCARashod(true)
  }
  const deleteIt = async (idd) => {
    try {
      seloading(true)

      const deleteProd = await $api.get(`${BACKURL}/api/incosations/delete/${idd}`)
      setIncosations(incosations.filter(item => item.id != idd))
      seloading(false)

      Notify.addNotification({
        title: "Готово!",
        message: "Инкассация успешно удалена из системы!",
        type: "success",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } catch (e) {
      console.log(e)
      seloading(false)

      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }
  const submit = async (func, title, message, arg) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await func(arg)
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "dqwdqwdwqdwq" && setAvansReportsFormsModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const months = [
      'янв', 'фев', 'мар', 'апр', 'май', 'июн',
      'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  const confirmation = async (id) => {
    try {
      seloading(true)
      const newProd = await $api.get(`${BACKURL}/api/avansreportsforms/confirm/${id}`)
      setAvansReports(avansReports.map(pr => {
        const test = newProd.data.AvansReports.find(gt => pr.id == gt.id)
        if (test) {
          return test
        } else return pr
      }))
      setAvansReportsForms(avansReportsForms.map(pr => {
        if (pr.id == newProd.data.AvansReportsForm.id) {
          return newProd.data.AvansReportsForm
        } else return pr
      }))
      // setThisReport(newProd.data)
      setAvansReportsFormsModal(false)
      seloading(false)
    } catch (e) {
      seloading(false)
      console.log(e)
    }
  }
  const InnerAccordion = ({ contr, gt, list, thisreport }) => {
    const deleteRashod = async (id) => {
      try {
        seloading(true)
        const newProd = await $api.post(`${BACKURL}/api/contragentsrashod/delete/${id}`, { avansreport_id: gt.avans_id, shop_id: gt.shop_id })
        setAvansReports(avansReports.map(pr => {
          if (pr.id == newProd.data.id) {
            return newProd.data
          } else return pr
        }))
        // setThisReport(newProd.data)
        seloading(false)
      } catch (e) {
        seloading(false)
        console.log(e)
      }
    }
    const adm = admins.find(pr => pr.id == gt.admin_id)
    return (
      <Accordion allowZeroExpanded style={{ width: '100%' }}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton><>
              <div style={{ display: 'flex', flexDirection: "column",alignItems:'center', justifyContent: 'space-evenly' }}>
                <div>
                  <b>{adm.fullname}</b>
                </div>
                <div style={{ display: 'flex', flexDirection: "row",alignItems:'center' ,width:'100%', justifyContent: 'space-between'}}>

                <div style={{ display: 'flex', flexDirection: "column",alignItems:'start' }}>
                  <div>
                    {contr.name}

                  </div>
                  <div>
                    <b>{gt.kategory}</b>
                  </div>
                </div>

                <div style={{ display: 'flex', flexDirection: "column" ,alignItems:'end'}}>
                  <div>
                    Наличныйе:<b>{gt.nal ? `${gt.nal} руб` : '0 руб'}</b>

                  </div>
                  <div>
                    Безналичные: <b>{gt.beznal ? `${gt.beznal} руб` : '0 руб'}</b>

                  </div>
                </div>
                </div>
              </div>

            </>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel style={{ background: 'wheat' }}>
            {(admin.role == 'Аминистратор' || admin.role == 'AllRights') && !thisreport.confirm && (


              <div style={{ display: 'flex', flexDirection: "row" }}>
                {/* <Button text='Изменить' background='gold'
                  width='100%' height='30px'
                  onClick={(e) => {
                    setdate(pr.date)
                    setthisNakladnaya(gt)
                    seteditCARashod(true)
                  }} /> */}
                {!thisreport.confirm && (<>
             
                     <Button text='Изменить' background='orange'
                     width='30%' height='30px'
                     onClick={(e) => OpnaEditPrihoModal(gt.id)} />
 
                  <Button text='Удалить' background='red'
                    width='100%' height='30px'
                    onClick={(e) => submit(deleteRashod, 'Удалить накладную?', 'После удаления накладную нельзя будет восстановить!', gt.id)} />
                  </>
                 )}

              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center" }}>

              {list.map((product, index) => {
                // console.log(product)
                return <div style={{ display: 'flex', width: "100%", flexDirection: 'row', alignItems: "center", justifyContent: "space-between" }}>
                  <div>
                    <b>{index + 1})</b>
                  </div>
                  <div>
                    {product.name}
                  </div>
                  <div>
                    <b>{product.qty}</b>{product.mnt}
                  </div>


                </div>
              })}
            </div>

          </AccordionItemPanel>
        </AccordionItem>

      </Accordion>
    )
  };
  return (
    <>
      {/* <ReactNotifications /> */}
      {avansReportsFormsModal && (
        <div
          onClick={closeModal}
          id="dqwdqwdwqdwq" className={style.modal_container}
        // style={{backgroundImage: "url('../../../pages/auth/unnamed-2.jpg')"}}
        >
          <div className={style.borders2} style={{ height: 'inherit' }}>
            {/* <h2 id={'select'} style={{ color: "white" }}> {shop.name}</h2> */}
            <h3>{thisShop.name}</h3>
            <h5>{formatDate(thisForm.from)}-{formatDate(thisForm.to)}</h5>
            {/* <h3 style={{ color: "white" }}>{(totlaMoney - totalsale).toFixed()} РУБ</h3> */}
            <div className={style.nextitem}>


              < Button
                text="Выход"
                background="var(--primary-color)"
                color="white"
                width="100px"
                onClick={() => {
                  setAvansReportsFormsModal(false)

                }}
              />
              {thisForm.status != 'Проверено' && (


                < Button
                  text="Проверено"
                  background="var(--primary-color)"
                  color="white"
                  width="100px"
                  onClick={() => {
                    submit(confirmation, 'Завершение работы над отчетами', 'Вы сохранили все последние изменения? Если нет - то вернитесь и сделайте это', id)

                  }}
                />
              )}
            </div>
            <div style={{ display: 'flex', width: "100%", flexDirection: 'column', textAlign: "center" }} >
              <Tabs >
                <TabList>
                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={"Продажи"}><h5>Выручка</h5></Tab>

                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Счетчики'}><h5>Расходы</h5></Tab>
                  <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Остатки'}><h5>Касса</h5></Tab>
                </TabList>


                <TabPanel>
                  {formReports.map(pr => {
                    const revenues = pr.revenues ? typeof pr.revenues == 'string' ? JSON.parse(pr.revenues) : pr.revenues : {
                      z_otchet: 0,
                      nal: 0,
                      beznal: 0,
                      rashods: []
                    }
                    let summinc = 0
                    incosations.filter(pr => pr.avans_id == pr.id).map(gt => {
                      if (gt.confirm) {
                        summinc = Number(summinc) + Number(gt.money)

                      }

                    })
                    let on = 0
                    let ob = 0
                    revenues.rashods.map(gt => {


                      const nakladnaya = contragentsPrihods.find(t => t.id == gt)
                      if(nakladnaya){
                           on = Number(on) + Number(nakladnaya.nal)
                      ob = Number(ob) + Number(nakladnaya.beznal)
                      }
                   
                    })
                    const kassa = pr.kassa ? typeof pr.kassa == 'string' ? JSON.parse(pr.kassa) : pr.kassa : {
                      incosations: [],
                      //привязать кассу точки
                      spendnal: on,
                      spendbeznal: ob,
                      startweek: 0,
                      endweek: 0,
                      ostatok: 0
                    }
                    const save = async (pr) => {
                      try {
                        seloading(true)
                        const newProd = await $api.post(`${BACKURL}/api/avansreports/save/${pr.id}`,
                          { thisReport: { ...pr, kassa: kassa, revenues: revenues }, admin: admin.id }
                        )
                        console.log(newProd)
                        seloading(false)
                        Notify.addNotification({
                          title: "Готово!",
                          message: "Авансовый отчет успешно сохранен!",
                          type: "success",
                          insert: "bottom",
                          container: "bottom-center",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                            duration: 5000,
                            onScreen: true
                          }
                        });

                      } catch (e) {
                        seloading(false)
                        Notify.addNotification({
                          title: "Ошибка!",
                          message: "Произошла непредвиденная ошибка!",
                          type: "danger",
                          insert: "bottom",
                          container: "bottom-center",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                            duration: 5000,
                            onScreen: true
                          }
                        })
                        console.log(e)
                      }
                    }

                    console.log(revenues)
                    return <>
                      <div className={style.nextitem}>
                        {!pr.confirm && (
                          <div className={style.uuu} style={{ flexDirection: 'row' }}>
                            <BiSolidSave style={{ color: "white" }} onClick={() => save(pr)}></BiSolidSave>

                          </div>
                        )}

                      </div>
                      <div style={{ color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflowX: 'hidden' }}>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <b>Дата</b>
                          </div>
                          <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <b>Менеджер</b>
                          </div>
                          <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <b>Часы</b>
                          </div>
                        </div>




                        <div
                          style={{ width: '100%', marginBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                          <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h5>{formatDate(pr.createdAt)}</h5>
                          </div>
                          <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h5>{pr.admin_id ? admins.find(tt => tt.id == pr.admin_id).fullname : admin.fullname}</h5>
                          </div>
                          <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <input
                              type="number"
                              inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                              pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                              step="1" // шаг ввода для ограничения на два знака после запятой
                              min="0" // минимальное значение
                              className={style.kassainput} onChange={(e) => {

                                const newavansReports = avansReports.map(pr1 => {

                                  if (pr1.id == pr.id) {
                                    // setThisReport({ ...thisReport, revenues: { ...revenues, hours: e.target.value } })
                                    return { ...pr, revenues: { ...revenues, hours: e.target.value } }
                                  } else {
                                    return pr1
                                  }
                                })
                                // console.log(newavansReports)
                                setAvansReports(newavansReports)
                              }}
                              disabled={!pr.confirm ? false : true}
                              value={revenues.hours}
                              style={{ width: "50px" }} placeholder="Часы" />
                          </div>
                        </div>
                        <div style={{ width: '100%', marginBottom: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                          <div style={{ width: '33%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <label>Z-Отчет</label>
                            <input
                              type="number"
                              inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                              pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                              step="0.01" // шаг ввода для ограничения на два знака после запятой
                              min="0" // минимальное значение
                              disabled={!pr.confirm ? false : true}

                              className={style.kassainput}
                              onChange={(e) => {

                                const newavansReports = avansReports.map(pr1 => {

                                  if (pr1.id == pr.id) {
                                    // setThisReport({ ...thisReport, revenues: { ...revenues, z_otchet: e.target.value } })
                                    return { ...pr, revenues: { ...revenues, z_otchet: e.target.value } }
                                  } else {
                                    return pr1
                                  }
                                })
                                // console.log(newavansReports)
                                setAvansReports(newavansReports)
                              }}

                              value={revenues.z_otchet} style={{ width: "80%" }} placeholder="Z-Отчет" />
                          </div>
                          <div style={{ width: '33%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <label>Наличные</label>

                            <input

                              className={style.kassainput}
                              value={(Number(revenues.z_otchet) - Number(revenues.beznal)).toFixed(2)} style={{ width: "80%" }} placeholder="Наличные" disabled />
                          </div>
                          <div style={{ width: '33%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <label>Безнал</label>

                            <input
                              type="number"
                              inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                              pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                              step="0.01" // шаг ввода для ограничения на два знака после запятой
                              min="0" // минимальное значение
                              className={style.kassainput}
                              disabled={!pr.confirm ? false : true}

                              onChange={(e) => {
                                const newavansReports = avansReports.map(pr1 => {

                                  if (pr1.id == pr.id) {
                                    // setpr({ ...thisReport, revenues: { ...revenues, beznal: e.target.value } })
                                    return { ...pr, revenues: { ...revenues, beznal: e.target.value } }
                                  } else {
                                    return pr1
                                  }
                                })
                                // console.log(newavansReports)
                                setAvansReports(newavansReports)
                              }}




                              value={revenues.beznal} style={{ width: "80%" }} placeholder="Безнал" />
                          </div>
                        </div>


                        <hr style={{ width: '100%', marginBottom: '15px', border: '1px black solid' }} />




                      </div>

                    </>
                  })}
                </TabPanel>
                <TabPanel>
                  {formReports.map(pr => {
                    const revenues = pr.revenues ? typeof pr.revenues == 'string' ? JSON.parse(pr.revenues) : pr.revenues : {
                      z_otchet: 0,
                      nal: 0,
                      beznal: 0,
                      rashods: []
                    }

                    return <>
                      {revenues.rashods.map(gt => {

                        const nakladnaya = contragentsPrihods.find(t => t.id == gt)
                        // console.log(gt,nakladnaya,contragentsPrihods)
                        if(nakladnaya){
                        const list = nakladnaya.list.map(oo => JSON.parse(oo))
                        const contr = contragents.find(pr1 => pr1.id == nakladnaya.contragent_id)
                        console.log(nakladnaya, list, contr)

                        return (
                          <>
                            <InnerAccordion contr={contr} gt={nakladnaya} list={list} thisreport={pr} />

                          </>
                        )
                      }
                      })}

                    </>
                  })}
                </TabPanel>
                <TabPanel>
                  {formReports.map(pr => {
                    const revenues = pr.revenues ? typeof pr.revenues == 'string' ? JSON.parse(pr.revenues) : pr.revenues : {
                      z_otchet: 0,
                      nal: 0,
                      beznal: 0,
                      rashods: []
                    }
                    let summinc = 0
                    const ttt = incosations.filter(rrrr => rrrr.avans_id == rrrr.id)
                    const ttt1 = ttt.map(gt => {
                      if (gt.confirm) {
                        summinc = Number(summinc) + Number(gt.money)

                      }

                    })
                    let on = 0
                    let ob = 0
                    revenues.rashods.map(gt => {


                      const nakladnaya = contragentsPrihods.find(t => t.id == gt)
                      on = Number(on) + Number(nakladnaya.nal)
                      ob = Number(ob) + Number(nakladnaya.beznal)
                    })
                    const kassa = pr.kassa ? typeof pr.kassa == 'string' ? JSON.parse(pr.kassa) : pr.kassa : {
                      incosations: [],
                      //привязать кассу точки
                      spendnal: on,
                      spendbeznal: ob,
                      startweek: 0,
                      endweek: 0,
                      ostatok: 0
                    }
                    const incosationsReport = incosations.filter(p1r => p1r.avans_id == pr.id)
                    // { ...oldkassa2, spendallnal: renderkassa.spendallnal, spendallbeznal: renderkassa.spendallbeznal, endweek: oldkassa2.startweek + renderkassa.nal - renderkassa.spendallnal }
                    console.log(revenues, kassa)
                    const save = async (pr) => {
                      try {
                        seloading(true)
                        const newProd = await $api.post(`${BACKURL}/api/avansreports/save/${pr.id}`,
                          { thisReport: { ...pr, kassa: kassa, revenues: revenues }, admin: admin.id }
                        )
                        console.log(newProd)
                        seloading(false)
                        Notify.addNotification({
                          title: "Готово!",
                          message: "Авансовый отчет успешно сохранен!",
                          type: "success",
                          insert: "bottom",
                          container: "bottom-center",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                            duration: 5000,
                            onScreen: true
                          }
                        });

                      } catch (e) {
                        seloading(false)
                        Notify.addNotification({
                          title: "Ошибка!",
                          message: "Произошла непредвиденная ошибка!",
                          type: "danger",
                          insert: "bottom",
                          container: "bottom-center",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                            duration: 5000,
                            onScreen: true
                          }
                        })
                        console.log(e)
                      }
                    }
                    return <>
                      <div className={style.nextitem}>
                        {!pr.confirm && (
                          <div className={style.uuu} style={{ flexDirection: 'row' }}>
                            <BiSolidSave style={{ color: "white" }} onClick={() => save(pr)}></BiSolidSave>

                          </div>
                        )}

                      </div>
                      <h2 style={{ color: 'white' }} disabled={!pr.confirm ? false : true}>Касса на начало {formatDate(pr.createdAt)}</h2>
                      <input value={kassa.startweek} onChange={(e) => {

                        const newavansReports = avansReports.map(pr1 => {

                          if (pr1.id == pr.id) {
                            // setThisReport({ ...pr, revenues: { ...kassa, startweek: e.target.value } })
                            return { ...pr, kassa: { ...kassa, startweek: e.target.value } }
                          } else {
                            return pr1
                          }
                        })
                        // console.log(newavansReports)
                        setAvansReports(newavansReports)
                      }} className={style.kassainput} placeholder="Касса на начало недели" />

                      <h2 style={{ color: 'white' }}>Израсходовано всего</h2>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <label>Наличные</label>
                          <input disabled value={on ? on : 0} style={{ width: '40%' }} className={style.kassainput} placeholder="Наличные" />

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <label>Безналичные</label>

                          <input disabled value={ob ? ob : 0} style={{ width: '40%' }} className={style.kassainput} placeholder="Безналичные" />

                        </div>


                      </div>
                      <h2 style={{ color: 'white' }}>Касса на конец {formatDate(pr.createdAt)}</h2>
                      <input disabled value={Number(kassa.startweek) + Number(Number(revenues.z_otchet) - Number(revenues.beznal)) - on} className={style.kassainput} placeholder="Касса на конец недели" />



                      <h2 style={{ color: 'white' }}>Инкасированно</h2>
                      {incosationsReport.length == 0 && (
                        <input value={'Нет инкасаций'} disabled={admin.role !== 'Аминистратор' && admin.role !== 'AllRights'} className={style.kassainput} placeholder="Инкасированно" />

                      )}
                      {incosationsReport.length > 0 && (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                              <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                <b>Инкассатор</b>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                <b>Сумма</b>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                <b>Подтвердить</b>
                              </div>
                            </div>


                            {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>

                          <div>
                            <b>Инкосатор</b>
                          </div>

                          <div>
                            <b>Сумма</b>
                          </div>

                          <div>
                            <b>Подтвердить</b>
                          </div>
                        </div> */}



                            {incosationsReport.map((pr3) => {
                              // const inc = incosations.find(hh => hh.id == pr3)
                              console.log(pr3)
                              const adm = admins.find(a => a.id == pr3.admin_id)
                              // const emp = admins.find(a => a.id == pr3.employee_id)
                              // const sh = shops.find(a => a.id == pr3.shop_id)
                              if (pr3) {
                                return <>
                                  <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center' }}>

                                    <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                      {adm.fullname}
                                    </div>

                                    <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                      {pr3.money}руб
                                    </div>
                                    <div style={{ display: 'flex', color: 'white', flexDirection: 'row', width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                                      {/* {pr.status == "Открыт" && !pr3.confirm && admin.role == 'Бариста' && <Button text='Подтвердить' background='gold'
                                    width='100%' height='30px'
                                    onClick={() => {
                                      submit(confirm, 'Инкосация', 'После подтверждения инкосации, сумму изменить будет невозможно!', pr3.id)
                                      // confirm(pr3.id)
                                      // }

                                    }} />} */}
                                      {/* {pr.status == "Открыт" && (<>



                                  </>) */}
                                      {/* } */}
                                      {pr.status == "Открыт" && !pr3.confirm && admin.role != 'Бариста' && <>
                                        <Button text='Изменить' background='gold'
                                          width='100%' height='30px'
                                          onClick={() => {
                                            OpnaEditModal(pr3.id)
                                          }} />
                                        <Button text='Удалить' background='gold'
                                          width='100%' height='30px'
                                          onClick={() => {
                                            submit(deleteIt, 'Удалить?', 'Удалить инкассацию из системы?', pr3.id)
                                          }} />
                                      </>}
                                      {pr3.confirm && (<>Подтверждено</>)}
                                    </div>         </div>

                                </>
                                // <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>

                                //   <div>
                                //    
                                //   </div>

                                //   <div>
                                //     {pr.money}руб
                                //   </div>

                                //   <div>
                                //     <Button text='Подтвердить' background='gold'
                                //       width='100%' height='50px'
                                //       onClick={() => {

                                //       }} />
                                //   </div>
                                // </div>
                              }
                            })}
                          </div>
                        </>
                        // <input value={'Нет инкасаций'} disabled={admin.role !== 'Аминистратор' && admin.role !== 'AllRights'} className={style.kassainput} placeholder="Инкасированно" />

                      )}
                      {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                    {thisReport.status == "Открыт" && (admin.role == 'Аминистратор' || admin.role == 'AllRights') && (
                      <>
                        <input value={summIncasations} onChange={(e) => {

                          // if (summIncasations >= 0) {
                          setSummIncasations(e.target.value)

                          // }

                        }}

                          className={style.kassainput} placeholder="Сумма Инкосации" />

                        <Button text={incosationsReport.length == 0 ? 'Инкасировать' : 'Инкасировать ещё'} background='gold'
                          width='200px' height='30px'
                          onClick={() => {


                            save(thisReport)
                            submit(Incasation, 'Инкосация', `Инкосировать ${summIncasations}руб?`)

                            // Incasation()
                          }} />
                      </>
                    )}
                  </div> */}
                      <h2 style={{ color: 'white' }}>Остаток в кассе</h2>
                      <input value={Number(kassa.startweek) + Number(Number(revenues.z_otchet) - Number(revenues.beznal)) - on - summinc} className={style.kassainput} placeholder="Остаток в кассе" />

                      <br />
                      <hr />
                    </>
                  })}
                </TabPanel>




              </Tabs >


            </div>


          </div>

        </div >
      )}
      {
        loading && (
          <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )
      }

      {editShopIncasationsModal && (
        <EditShopIncasationsModal id={idi} />)}
 {editCARashod && (
        <EditContrAgentsRashodModal id={edit_id} />)}


    </>
  )
}
export default AvansReportForm;