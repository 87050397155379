import React from "react";
import style from "./Home.module.css";
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import PaginationComponent from '../../../components/Pagination/Pagination.js'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcEngineering, FcInfo } from "react-icons/fc";
import $api from '../../../http/index.ts'
import './react-confirm-alert.css'; // Import css
import DatePicker from "react-datepicker";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Calendar from 'react-calendar';
import { SiMicrosoftexcel } from "react-icons/si";
import 'react-calendar/dist/Calendar.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import 'react-accessible-accordion/dist/fancy-example.css';
import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
// import AddProductModal from "../../../components/Modals/AddProductModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
// import AddEmployeesModal from "../../../components/Modals/Emloyees/AddEmployeesModal.js";
// import EditEmployeesModal from "../../../components/Modals/Emloyees/EditEmployeesModal.js";
// import AddRecieptModal from "../../../components/Modals/Reciepts/AddRecieptModal.js";
// import RecieptListModal from "../../../components/Modals/Reciepts/RecieptListModal.js";
import { CiFloppyDisk } from "react-icons/ci";
import { RiFileDownloadLine } from "react-icons/ri";
import CreateSRModal from "../../../components/Modals/Reports/CreateSaleReport.js";
import styled from "styled-components";
import SRListModal from "../../../components/Modals/Reports/SRListModal.js";
import { ru } from 'date-fns/locale';
import SaleReportShort from "../../../components/Modals/Reports/SaleReportShort.js";
import SaleReport from "../../../components/Modals/Reports/SaleReport.js";
import EditSaleReportForm from "../../../components/Modals/Reports/EditSaleReportForm.js";
import AvansReport from "../../../components/Modals/Reports/AvansReport.js";
import AvansReportForm from "../../../components/Modals/Reports/AvansReportForm.js";
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const ReportsPage = () => {
  const { avansReportsFormsModal, setAvansReportsFormsModal, avansReportsForms, setAvansReportsForms, setAvansReportModal, avansReportModal, setAvansReports, avansReports, saleReportShortShow, setSaleReportShortShow, checkAuth, editSaleReportForm, setEditSaleReportForm, saleReportShow, setSaleReportShow, Notify, srListModal, saleReports, setSaleReports, setSRListModal, saleReportForms, setSaleReportForms, shops, createSRModal, setCreateSRModal, editRecieptModal, setEditRecieptModal, setReciepts, recieptListModal, setRecieptListModal, reciepts, addReciepModal, setAddRecieptModal, editEmployeesModal, setEditEmployeesModal, admin, admins, setAdmins, addEmployeesModal, setAddEmployeesModal, setDashboarPage, smenas } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [form_id, setForm_id] = useState(null)
  const animatedComponents = makeAnimated();
  const [id, setId] = useState('')
  const [srid, stSrid] = useState(null)
  const opendSmenas = smenas.find(pr => pr.open)
  const [orderId, setOrderId] = useState({})
  const [valueCalendar, onChangeCalendar] = useState<Value>(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(15);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [shop, setShop] = useState(null);
  const itemsPerPage = 10;
  const [values, setValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [pageForms, setPageForms] = useState(1);
  const [pageSizeForms, setPageSizeForms] = useState(5);

  const [startDateForm, setStartDateForm] = useState(null);
  const [endDateForm, setEndDateForm] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [formErr, setFormErr] = useState(null);
  const formReport = async () => {
    console.log(startDateForm, endDateForm, selectedShop)
    try {
      seloading(true)
      setFormErr(null)
      const data = await $api.post(`${BACKURL}/api/avansreportsforms/create/${selectedShop}`, { endDateForm: endDateForm, startDateForm: startDateForm, admin_id: admin.id })
      setAvansReportsForms([...avansReportsForms, data.data.newForm])
      setAvansReports(avansReports.map(pr => {
        const test = data.data.AvansReports.find(g => g.id == pr.id)
        if (test) {
          return test
        } else { return pr }
      }))
      console.log(data.data)
      seloading(false)
    } catch (e) {
      seloading(false)
      setFormErr(e.response.data.message)
      console.log(e)
    }
  };

  const formReportXLSX = async (id) => {
    // console.log(startDateForm, endDateForm, selectedShop)
    try {
      seloading(true)
      // setFormErr(null)
      const data = await $api.get(`${BACKURL}/api/avansreportsforms/sendxlsx/${id}`)
      // setAvansReportsForms([...avansReportsForms, data.data.newForm])
      // setAvansReports(avansReports.map(pr => {
      //   const test = data.data.AvansReports.find(g => g.id == pr.id)
      //   if (test) {
      //     return test
      //   } else { return pr }
      // }))
      console.log(data.data)
      seloading(false)
    } catch (e) {
      seloading(false)
      // setFormErr(e.response.data.message)
      console.log(e)
    }
  };

  const onOptionChange = async (options) => {
    // Selected options...
    // console.log("options...", options);
    seloading(true)
    try {

      setSelectedValues(options);
      const data = await $api.post(`${BACKURL}/api/salereports/getall/${1}`, { pageSize: pageCount, shops: selectedValues.map(pr => pr.value), startdate: startDate, enddate: endDate })
      setCurrentPage(1);
      setSaleReports(data.data)
      seloading(false)
    } catch (e) {
      console.log(e)
      seloading(false)
    }
  };
  useEffect(() => {
    const AvansReports2 = async () => {
      seloading(true)
      if (admin.role != 'Кладовщик') {
        const data = await $api.post(`${BACKURL}/api/avansreports/getallshops`, { pageSize: pageSize, page: page })
        // console.log(data.data);
        setAvansReports(data.data)
      }
      seloading(false)


    }
    AvansReports2()
  }, [page, pageSize])
  useEffect(() => {
    const AvansReports2Forms = async () => {
      seloading(true)
      if (admin.role != 'Кладовщик') {
        const data = await $api.post(`${BACKURL}/api/avansreportsforms/getall`, { pageSize: pageSizeForms, page: pageForms })
        // console.log(data.data);
        setAvansReportsForms(data.data)
      }
      seloading(false)


    }
    AvansReports2Forms()
  }, [pageForms, pageSizeForms])
  const onChangeCalendar1 = async (options) => {
    // Selected options...
    onChangeCalendar(options)
    console.log("options...", options);
    seloading(true)
    try {
      // setSelectedValues(options);
      console.log(new Date(options))
      console.log(new Date(startDate))
      const data = await $api.post(`${BACKURL}/api/salereports/getall/${1}`, {
        pageSize: 20, shops: selectedValues.map(pr => pr.value), startdate: new Date(options), enddate: new Date(options)
      })
      setCurrentPage(1);
      setpageCount(20)
      setSaleReports(data.data)
      seloading(false)
    } catch (e) {
      console.log(e)
      seloading(false)
    }
  };



  const [selectedReport, setSelectedReport] = useState(null);
  const avansReportForm = (id) => {
    // setShop(shops.find(pr => pr.id == report.shop_id))
    // setSelectedReport(report)
    setForm_id(id)
    setAvansReportsFormsModal(true)
  }
  const avansReport = (report) => {
    setShop(shops.find(pr => pr.id == report.shop_id))
    setSelectedReport(report)
    setAvansReportModal(true)
  }
  console.log('valueCalendar', valueCalendar)
  const onStartChange = async (options) => {
    // Selected options...
    // console.log("options...", options);
    seloading(true)
    try {
      setStartDate(options);
      const data = await $api.post(`${BACKURL}/api/salereports/getall/${1}`, { pageSize: 15, shops: selectedValues.map(pr => pr.value), startdate: options, enddate: endDate })
      setCurrentPage(1);
      setSaleReports(data.data)
      seloading(false)
    } catch (e) {
      console.log(e)
      seloading(false)
    }
  };
  const onEndChange = async (options) => {
    // Selected options...
    // console.log("options...", options);
    seloading(true)
    try {
      setEndDate(options);
      const data = await $api.post(`${BACKURL}/api/salereports/getall/${1}`, { pageSize: pageCount, shops: selectedValues.map(pr => pr.value), startdate: startDate, enddate: options })
      setCurrentPage(1);
      setSaleReports(data.data)
      seloading(false)
    } catch (e) {
      console.log(e)
      seloading(false)
    }
  };
  useEffect(() => {

    shops.map(pr => { return { value: pr.id, label: pr.name } })
    const data = shops.map(pr => { return { value: pr.id, label: pr.name } })

    setValues(data);
    setSelectedValues(data);


    const AvansReports2 = async () => {
      seloading(true)
      if (admin.role != 'Кладовщик') {
        const data = await $api.post(`${BACKURL}/api/avansreports/getallshops`, { pageSize: pageSize, page: page })
        // console.log(data.data);
        setAvansReports(data.data)
      }
      seloading(false)


    }
    AvansReports2()
  }, []);
  // const pageCount = 10; . // Замените на фактическое значение

  // Обработка изменения страницы
  const handlePageChange = async (selectedPage) => {
    seloading(true)
    try {
      setCurrentPage(selectedPage);
      const data = await $api.post(`${BACKURL}/api/salereports/getall/${selectedPage}`, { pageSize: pageCount, shops: selectedValues.map(pr => pr.value), startdate: startDate, enddate: endDate })

      setSaleReports(data.data)

      seloading(false)
      return
    } catch (e) {
      seloading(false)

    }

  };
  const handlePageCountChange = async (selectedPage) => {
    seloading(true)
    try {
      setpageCount(selectedPage);
      const data = await $api.post(`${BACKURL}/api/salereports/getall/${1}`, { pageSize: selectedPage, shops: selectedValues.map(pr => pr.value), startdate: startDate, enddate: endDate })

      setSaleReports(data.data)

      seloading(false)
      return
    } catch (e) {
      seloading(false)

    }

  };


  if (!(admin.role == 'AllRights' || admin.role == 'Администратор')) {
    setDashboarPage('Home')
    // setAdmin({ auth: false});
    return <Navigate to="/" replace />

  } else {

    const TCheckBox = styled.input.attrs(props => ({
      type: "checkbox"
    }))`
      display: none;
    `;
    const TCheckBoxWrapper = styled.div<{ checked: boolean }>`
      background: ${p => (p.checked ? "#3f81ff" : "#ececec")};
      border-radius: 4rem;
      height: 32px;
      width: 68px;
      position: relative;
      transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
      cursor: pointer;
    `;

    const TCheckBoxToggle = styled.div<{ checked: boolean }>`
      background: #fff;
      border-radius: 4rem;
      height: 26px;
      width: 26px;
      top: 3px;
      left: ${p => (p.checked ? "38px" : "4px")};
      position: absolute;
      transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
    `;
    const ToggleCheckbox = props => {
      return (
        <TCheckBoxWrapper checked={props.checked} onClick={props.onChange}>
          <TCheckBoxToggle checked={props.checked} />
          <TCheckBox {...props} />
        </TCheckBoxWrapper>
      );
    };
    const toggle = async (id, showed) => {
      try {
        seloading(true)
        const refresh = await checkAuth()
        if (refresh) {
          const deleteProd = await $api.get(`${BACKURL}/api/scladshops/switch/${id}`)
          setSaleReportForms(deleteProd.data)
          Notify.addNotification({
            title: "Готово!",
            message: showed ? 'Форма отчета о продажах отключена!' : 'Форма отчета о продажах включена!',
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        seloading(false)

      } catch (e) {
        console.log(e)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    };
    const addProduct = () => {
      setCreateSRModal(true)
    }
    const handleDateChange = dates => {
      console.log(dates)
      const [start, end] = dates;
      setStartDateForm(start);
      setEndDateForm(end);
    };
    const deleteIt = async (id) => {
      try {
        seloading(true)
        const refresh = await checkAuth()
        if (refresh) {
          const deleteProd = await $api.get(`${BACKURL}/api/salereports/deleteform/${id}`)
          setSaleReportForms(deleteProd.data)
          Notify.addNotification({
            title: "Готово!",
            message: "Форма отчета о продажах успешно удалена!",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        seloading(false)

      } catch (e) {
        seloading(false)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        console.log(e)
      }
    }
    const submit2 = async (func, title, message, arg) => {
      confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(arg)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    const submit = async (func, id) => {
      confirmAlert({
        title: 'Удалить отчет?',
        message: 'Вы уверены, что хотите удалить форму отчета из системы?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };


    const deleteSR = async (id) => {
      try {
        seloading(true)
        const refresh = await checkAuth()
        if (refresh) {
          const deleteProd = await $api.get(`${BACKURL}/api/salereports/delete/${id}`)
          setSaleReports(saleReports.filter(item => item.id != id))
          Notify.addNotification({
            title: "Готово!",
            message: "Отчет удален из системы!",
            type: "success",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        seloading(false)

      } catch (e) {
        seloading(false)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        console.log(e)
      }
    }
    const submitSR = async (func, id) => {
      confirmAlert({
        title: 'Удалить отчет?',
        message: 'Вы уверены, что хотите удалить отчет из системы?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    const showCounters = (id) => {
      setOrderId(id)
      setSRListModal(true)
    }
    const showSR = (id) => {
      stSrid(id)
      setSaleReportShow(true)
    }
    const showSRShort = (id) => {
      stSrid(id)
      setSaleReportShortShow(true)
    }
    const editSR = (id) => {
      stSrid(id)
      setEditSaleReportForm(true)
    }


    const sorted = saleReports.sort((a, b) => a.id < b.id ? 1 : -1)
    return (<>

      <div className={style.sclad_container}>
        <Button text='Создать Статистику Продаж' width='350px' background='orange' onClick={addProduct} />


        <Accordion allowZeroExpanded style={{ width: '100%' }}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Формы Отчетов продаж
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {saleReportForms && (
                <div className={style.table2}>
                  <table className={style.table}>
                    <thead className={style.tableth}>

                      <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>Точки</td>
                        <td className={style.tabletd}>Счетчики</td>
                        <td className={style.tabletd}>on/off</td>
                        {!opendSmenas && (
                          <td className={style.tabletd}>Действия</td>
                        )}

                      </tr>
                    </thead>
                    {saleReportForms.map(prod2 => {
                      let shopss = ''
                      // console.log(prod2.shops)
                      for (let i = 0; i < prod2.shops.length; i++) {
                        const dd = shops.find(pr => pr.id == Number(prod2.shops[i]))
                        shopss = shopss + `${dd.name}, `
                      }
                      return (<tbody>

                        <tr>
                          <td className={style.tabletd}>{prod2.id}</td>
                          <td className={style.tabletd}>{shopss}</td>
                          <td className={style.tabletd}>
                            <Button text='Счетчики' background='orange' onClick={() => showCounters(prod2.id)} />

                          </td>

                          <td className={style.tabletd}>
                            <div>
                              <ToggleCheckbox checked={prod2.showed} onChange={() => toggle(prod2.id, prod2.showed)} />
                            </div>
                          </td>

                          {/* //// {!opendSmenas && ( */}


                          <td className={style.tabletd}>
                            <div className={style.cells}>

                              <div className={style.cell}>
                                <FcEngineering
                                  onClick={() =>
                                    editSR(prod2.id)
                                  }
                                />
                              </div>
                              <div className={style.cell}>
                                <FcCancel
                                  onClick={() => submit(deleteIt, prod2.id)}
                                />
                              </div>
                            </div>
                          </td>
                          {/* // )} */}


                        </tr>



                      </tbody>
                      )
                    }

                    )}
                  </table>
                </div>


              )}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Статистика продаж
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {saleReports && (
                <div className={style.table2}>
                  <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}> */}

                    <div style={{ width: '100%' }}>
                      <PaginationComponent
                        data={saleReports}
                        handlePageCountChange={handlePageCountChange}
                        currentPage={currentPage}
                        itemsPerPage={pageCount}
                        onPageChange={handlePageChange}
                      />
                    </div>
                    <div style={{ width: '100%' }}>
                      <div style={{ width: '100%', display: "flex", flexDirection: 'column' }}>
                        <h4 style={{ color: 'white' }}>Начало</h4>
                        <div>
                          <DatePicker selected={startDate} onChange={(date) => onStartChange(date)} />

                        </div>
                      </div>
                      <div style={{ width: '100%', display: "flex", flexDirection: 'column' }}>
                        <h4 style={{ color: 'white' }}>Конец</h4>
                        <div>
                          <DatePicker selected={endDate} onChange={(date) => onEndChange(date)} />

                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div style={{ width: '100%' }}>
                      <Select
                        closeMenuOnSelect={false}
                        value={selectedValues}
                        isMulti
                        options={values}
                        onChange={onOptionChange}
                      />
                    </div>

                  </div>
                  <table className={style.table}>
                    <thead className={style.tableth}>

                      <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>Дата</td>
                        {/* <td className={style.tabletd}>Смена</td> */}
                        <td className={style.tabletd}>Точка</td>
                        <td className={style.tabletd}>Сотрудник</td>
                        <td className={style.tabletd}>Отчет</td>
                        <td className={style.tabletd}>Статус</td>

                        <td className={style.tabletd}>Действия</td>


                      </tr>
                    </thead>
                    {saleReports.map(prod2 => {
                      // let shopss = ''
                      // console.log(prod2.shops)
                      // for (let i = 0; i < prod2.shops.length; i++) {
                      const dd = shops.find(pr => pr.id == prod2.shop_id)
                      const smena = smenas.find(pr => pr.id == prod2.smena_id)
                      const user = admins.find(pr => pr.id == prod2.admin_id)
                      //   shopss = shopss + `${dd.name} /`
                      const date = new Date(prod2.createdAt);

                      // Форматирование времени в формате HH:mm
                      // const time = ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2);

                      // Форматирование даты в формате YYYY-MM-DD
                      const formattedDate = date.toISOString().slice(0, 10);
                      return (<tbody>

                        <tr>
                          <td className={style.tabletd}>{prod2.id}</td>
                          <td className={style.tabletd}>{formattedDate}</td>
                          {/* <td className={style.tabletd}>{smena.id}</td> */}
                          <td className={style.tabletd}><b>{dd.name}</b></td>
                          <td className={style.tabletd}>{user.fullname}</td>
                          <td className={style.tabletd}>
                            <Button text='Отчет' background='orange'
                              onClick={() => showSR(prod2.id)}
                            />

                          </td>
                          <td className={style.tabletd}>{prod2.status}</td>




                          <td className={style.tabletd}>
                            <div className={style.cells}>


                              <div className={style.cell}>
                                <CiFloppyDisk
                                // onClick={() =>
                                //   OpnaEditModal(employee.id)
                                // }
                                />
                              </div>
                              {/* <div className={style.cell}>
                                <FcEngineering
                                // onClick={() =>
                                //   OpnaEditModal(employee.id)
                                // }
                                />
                              </div> */}
                              <div className={style.cell}>
                                <FcCancel
                                  onClick={() => submitSR(deleteSR, prod2.id)}
                                />
                              </div>
                            </div>
                          </td>



                        </tr>



                      </tbody>
                      )
                    }

                    )}
                  </table>

                </div>


              )}
            </AccordionItemPanel>

          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Статистика продаж(Краткая)
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className={style.table2}>
                <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                  <Calendar style={{ width: '100%' }} onChange={async (e) => await onChangeCalendar1(e)} value={valueCalendar} />
                </div>
                <div style={{ width: '100%' }}>

                  {saleReports.map(prod2 => {
                    // let shopss = ''
                    // console.log(prod2.shops)
                    // for (let i = 0; i < prod2.shops.length; i++) {
                    const dd = shops.find(pr => pr.id == prod2.shop_id)
                    const smena = smenas.find(pr => pr.id == prod2.smena_id)
                    const user = admins.find(pr => pr.id == prod2.admin_id)
                    //   shopss = shopss + `${dd.name} /`
                    const date = new Date(prod2.createdAt);

                    // Форматирование времени в формате HH:mm
                    // const time = ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2);

                    // Форматирование даты в формате YYYY-MM-DD
                    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`
                    return (
                      // <tbody>
                      <>
                        <div style={{ borderBottom: "2px solid white", width: '100%', display: "flex", textAlign: "center", flexDirection: 'row', justifyContent: "left", alignItems: 'center', color: "white" }}>
                          <div style={{ width: '10%' }}>
                            {formattedDate}</div>
                          <div style={{ width: '20%' }}>
                            <Button text='Отчет' width='50px' background='orange'
                              onClick={() => showSRShort(prod2.id)}
                            />
                          </div>

                          <div style={{ width: '40%', fontSize: '11px' }}>
                            {dd.name}</div>
                          <div style={{ width: '30%', fontSize: '11px' }}>
                            {user.fullname.split(' ')[0]}</div>

                        </div>
                        {/* <hr style={{borderTop:'solid 1px white', border:"none"}}/> */}
                      </>
                      // <tr>
                      //   <td className={style.tabletd}>{formattedDate}</td>
                      //   <td className={style.tabletd}><b>{dd.name}</b></td>
                      //   <td className={style.tabletd}>{user.fullname}</td>
                      //   <td className={style.tabletd}>
                      //     <Button text='Отчет' background='orange'
                      //       onClick={() => showSRShort(prod2.id)}
                      //     />

                      //   </td>
                      // </tr>



                      // </tbody>
                    )
                  }

                  )}
                  {/* </table> */}
                </div>
              </div>
            </AccordionItemPanel>

          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Авансовые отчеты ежедневные
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className={style.table2}>

                {/* <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                  <Calendar style={{ width: '100%' }} onChange={async (e) => await onChangeCalendar1(e)} value={valueCalendar} />
                </div> */}
                <div style={{ width: '100%' }}>
                  <div style={{ flexDirection: 'row', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <div style={{ flexDirection: 'column', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                      <label >Страница</label>

                      <select name="pets" id="prod"
                        onChange={async (event) => {
                          setPage(event.target.value)
                          // setLoading(true)
                          // await avansReports()
                          // setLoading(false)

                        }}
                      >
                        <option value={page}>{page}</option>

                        <option value={1}>{1}</option>
                        <option value={2}>{2}</option>
                        <option value={3}>{3}</option>
                        <option value={4}>{4}</option>
                        <option value={5}>{5}</option>




                      </select>
                    </div>
                    <div style={{ flexDirection: 'column', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                      <label >Разрядность</label>

                      <select name="pets" id="prod"
                        onChange={async (event) => {
                          setPageSize(event.target.value)
                          // setLoading(true)
                          // await avansReports()
                          // setLoading(false)

                        }}
                      >
                        <option value={pageSize}>{pageSize}</option>

                        <option value={5}>{5}</option>
                        <option value={10}>{10}</option>
                        <option value={15}>{15}</option>
                        <option value={30}>{30}</option>




                      </select>
                    </div>


                  </div>
                  {avansReports.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                  }).map(prod2 => {
                    // let shopss = ''
                    console.log(prod2)
                    // for (let i = 0; i < prod2.shops.length; i++) {
                    const dd = shops.find(pr => pr.id == prod2.shop_id)
                    const smena = smenas.find(pr => pr.id == prod2.smena_id)
                    const user = admins.find(pr => pr.id == prod2.admin_id)
                    //   shopss = shopss + `${dd.name} /`
                    const date = new Date(prod2.createdAt);

                    // Форматирование времени в формате HH:mm
                    // const time = ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2);

                    // Форматирование даты в формате YYYY-MM-DD
                    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`
                    return (
                      // <tbody>
                      <>
                        <div style={{ borderBottom: "2px solid white", width: '100%', display: "flex", textAlign: "center", flexDirection: 'row', justifyContent: "left", alignItems: 'center', color: "white" }}>
                          <div style={{ width: '10%' }}>
                            {formattedDate}</div>
                          <div style={{ width: '20%' }}>
                            <Button text='Отчет' width='50px' background={prod2.status == 'Открыт' ? 'orange' : 'lightgrey'}
                              onClick={() => avansReport(prod2)}
                            />
                          </div>


                          <div style={{ width: '35%', fontSize: '11px' }}>
                            {user.fullname.split(' ')[0]}</div>
                          <div style={{ width: '35%', fontSize: '11px', background: prod2.confirm ? 'green' : "grey" }}>
                            {prod2.status}

                          </div>
                        </div>

                        {/* <hr style={{borderTop:'solid 1px white', border:"none"}}/> */}
                      </>
                      // <tr>
                      //   <td className={style.tabletd}>{formattedDate}</td>
                      //   <td className={style.tabletd}><b>{dd.name}</b></td>
                      //   <td className={style.tabletd}>{user.fullname}</td>
                      //   <td className={style.tabletd}>
                      //     <Button text='Отчет' background='orange'
                      //       onClick={() => showSRShort(prod2.id)}
                      //     />

                      //   </td>
                      // </tr>



                      // </tbody>
                    )
                  }

                  )}
                  {/* </table> */}
                </div>
              </div>
            </AccordionItemPanel>

          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Авансовые отчеты сформированные
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className={style.table2} style={{ alignItems: 'center' }}>
                <div>
                  <select value={selectedShop} onChange={(e) => setSelectedShop(e.target.value)}>
                    <option value={null}>Выберите точку</option>
                    {shops.map(pr => <option value={pr.id}>{pr.name}</option>
                    )}
                  </select>
                </div>
                <div style={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <h4 style={{ color: 'white' }}>Начало</h4>
                    <div>
                      <DatePicker locale={ru}
                        includeDates={avansReports.filter(pr => selectedShop == pr.shop_id && pr.form_id == null).map(f => new Date(f.createdAt))} // Restrict selectable dates
                        highlightDates={[
                          {
                            'highlight-yellow': avansReports.filter(pr => selectedShop == pr.shop_id).map(f => {
                              console.log(f)
                              return new Date(f.createdAt)
                            }), // Class to apply the yellow color
                          },
                        ]}
                        selected={startDateForm} onChange={(date) => setStartDateForm(date)} />

                    </div>
                  </div>
                  <div style={{ display: "flex", textAlign: 'center', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <h4 style={{ color: 'white' }}>Конец</h4>
                    <div>
                      <DatePicker locale={ru}
                        includeDates={avansReports.filter(pr => selectedShop == pr.shop_id && pr.form_id == null).map(f => new Date(f.createdAt))} // Restrict selectable dates
                        highlightDates={[
                          {
                            'highlight-yellow': avansReports.filter(pr => selectedShop == pr.shop_id).map(f => {
                              console.log(f)
                              return new Date(f.createdAt)
                            }), // Class to apply the yellow color
                          },
                        ]}
                        selected={endDateForm} onChange={(date) => setEndDateForm(date)} />

                    </div>
                  </div>
                </div>
                {/* <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                  <DatePicker
                    selected={startDateForm}
                    onChange={handleDateChange}
                    startDate={startDateForm}
                    endDate={endDateForm}
                    selectsRange
                    inline
                    locale={ru}
                    includeDates={avansReports.filter(pr => selectedShop == pr.shop_id).map(f => new Date(f.createdAt))} // Restrict selectable dates
                    highlightDates={[
                      {
                        'highlight-yellow': avansReports.filter(pr => selectedShop == pr.shop_id).map(f => {
                          console.log(f)
                          return new Date(f.createdAt)
                        }), // Class to apply the yellow color
                      },
                    ]}
                  />




                </div> */}

                <div style={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Button width={'50px'} text='Сформировать' background='orange' onClick={() => submit2(formReport, 'Формирование отчета', 'Сформировать новый отчет из нескольких дней?', null)} />
                  {formErr && (<p>{formErr}</p>)}
                </div>
                <div style={{ flexDirection: 'row', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ flexDirection: 'column', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <label >Страница</label>
                    <select name="pets" id="prod"
                      onChange={(event) => {
                        setPageForms(event.target.value)
                        // setLoading(true)
                        // await avansReports()
                        // setLoading(false)

                      }}
                    >
                      <option value={pageForms}>{pageForms}</option>
                      <option value={1}>{1}</option>
                      <option value={2}>{2}</option>
                      <option value={3}>{3}</option>
                      <option value={4}>{4}</option>
                      <option value={5}>{5}</option>
                    </select>
                  </div>
                  <div style={{ flexDirection: 'column', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <label >Разрядность</label>

                    <select name="pets" id="prod"
                      onChange={(event) => {
                        setPageSizeForms(event.target.value)
                        // setLoading(true)
                        // await avansReports()
                        // setLoading(false)

                      }}
                    >
                      <option value={pageSizeForms}>{pageSizeForms}</option>

                      <option value={5}>{5}</option>
                      <option value={10}>{10}</option>
                      <option value={15}>{15}</option>
                      <option value={30}>{30}</option>




                    </select>
                  </div>


                </div>
                <div style={{ width: '100%' }}>

                  {avansReportsForms.sort(function (a, b) {

                    return new Date(b.createdAt) - new Date(a.createdAt);
                  }).map(prod2 => {
                    console.log('prod2', prod2)
                    // const dd = shops.find(pr => pr.id == prod2.shop_id)
                    // const smena = smenas.find(pr => pr.id == prod2.smena_id)
                    const shop = shops.find(pr => pr.id == prod2.shop_id)
                    const date = new Date(prod2.from);
                    const date2 = new Date(prod2.to);
                    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}`
                    const formattedDate2 = `${date2.getDate()}/${date2.getMonth() + 1}`
                    return (
                      <>
                        <div style={{ borderBottom: "2px solid white", width: '100%', display: "flex", textAlign: "center", flexDirection: 'row', justifyContent: "left", alignItems: 'center', color: "white" }}>
                          <div style={{ width: '20%', fontSize: '8px' }}>
                            {formattedDate}-{formattedDate2}
                          </div>
                          <div style={{ width: '20%' }}>
                            <Button text='Отчет' width='50px' background={prod2.status == 'Не проверено' ? 'orange' : 'lightgrey'}
                              onClick={() => avansReportForm(prod2.id)}
                            />
                          </div>

                          <div style={{ width: '20%', fontSize: '8px', fontSize: '10px' }}>
                            {shop.name}
                          </div>
                          {prod2.status == 'Проверено' && (
                            <div style={{ width: '20%', fontSize: '8px' }}>
                              <SiMicrosoftexcel
                                onClick={() =>
                                  formReportXLSX(prod2.id)
                                }
                              />
                            </div>
                          )}

                          <div style={{ width: '20%', fontSize: '8px', background: prod2.status == 'Проверено' ? 'green' : "grey" }}>
                            {prod2.status}

                          </div>
                        </div>

                      </>
                    )
                  }

                  )}
                </div>
              </div>
            </AccordionItemPanel>

          </AccordionItem>
        </Accordion >



      </div >
      {editSaleReportForm && (
        <EditSaleReportForm
          id={srid}

        />

      )}
      {avansReportsFormsModal && (
        <AvansReportForm id={form_id}>

        </AvansReportForm>
      )}
      {avansReportModal && (
        <AvansReport
          // CloseSmenaReturn={CloseSmenaReturn}
          shop={shop}

          selectedReport={selectedReport}

        />

      )}      {
        saleReportShow && (
          <SaleReport
            id={srid}

          />

        )
      }
      {
        saleReportShortShow && (
          <SaleReportShort
            id={srid}

          />

        )
      }
      {
        createSRModal && (
          <CreateSRModal


          />

        )
      }
      {
        srListModal && (
          <SRListModal
            id={orderId}

          />

        )
      }


      {
        loading && (
          <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )
      }


    </>
    )
  }


};

export default ReportsPage;
