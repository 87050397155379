import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { FcCancel, FcServices } from "react-icons/fc";
// import DatePicker from "react-datepicker";
import { ru } from 'date-fns/locale';

import "react-datepicker/dist/react-datepicker.css";
const AddContrAgentsRashodModal = ({ shop_id, avansreport_id, date, smena_id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [err1, setErr1] = useState({
    prod_id: false,
    kategory: false,
    qty: false,
    type: false,
  })
  const { shopKassas, setShopKassas, smenas, setSmenas, contragentsPrihods, setContrAgentsPrihods, sclad, addCARashod, setaddCARashod, contragents, setScladHistoryShops, Notify, scladHistoryShops, setAvansReports, avansReports, scladShops, setScladShops, admin } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({
    shop_id: shop_id,
    avansreport_id: avansreport_id,
    contragent: null,
    smena_id: smena_id,
    kategory: null,
    nal: 0,
    beznal: 0,

    // kategory:null,

    doc_date: null,
    doc_number: null,
    // dogovor_date: null,
    // bank_kpp: null,
    // bank_rs: null,
    // bank_ks: null,

  })
  const [prodArr, setProdArr] = useState([])
  const dlt = (prod_id) => {
    setProdArr(prodArr.filter(pr => pr.prod_id != prod_id))
  }
  const [newValue1, setNeValue1] = useState({
    // shop_id: sh,
    type: 'Приход',
    kategory: '',
    prod_id: '',
    qty: '',

  })
  const addProd = async (e) => {
    e.preventDefault()
    let t = {
      prod_id: false,
      kategory: false,
      qty: false,
      type: false,
    }
    if (!newValue1.prod_id) {
      t.prod_id = `Выберите товар!`

    }
    if (!newValue1.qty || Number(newValue1.qty) < 0) {
      t.qty = `Введите положительное число!`

    }

    // }
    setErr(t)
    if (
      !t.prod_id &&
      !t.qty &&
      newValue1.prod_id,
      newValue1.qty

    ) {
      const product = sclad.find(pr => pr.id == Number(newValue1.prod_id))

      setProdArr([...prodArr, {
        prod_id: newValue1.prod_id,
        qty: newValue1.qty,
        name: product.name,
        kategory: product.kategory,
        mnt: product.mnt,
      }])

      setNeValue1({
        type: 'Приход',
        // shop_id: shop_id,
        kategory: newValue1.kategory,
        prod_id: '',
        qty: '',
      })
    }
  }
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12464" && setaddCARashod(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory)));
  // console.log(uniqueSerials, sclad)
  const nosirops = sclad.filter(pr => pr.kategory != 'Сироп')
  const sorted = nosirops.sort((a, b) => a.name > b.name ? 1 : -1);

  const [err, setErr] = useState({
    contragent: false,
    email: false,
    inn: false,
    bank_name: false,
    dogovor_number: false,
    dogovor_date: false,
    bank_kpp: false,
    bank_rs: false,
    bank_ks: false,

  })

  const editPrice = async () => {
    try {
      // e.preventDefault()

      let t = {
        name: false,
        email: false,
        inn: false,
        bank_name: false,
        dogovor_number: false,
        dogovor_date: false,
        bank_kpp: false,
        bank_rs: false,
        bank_ks: false,
      }

      // if (!newValue.name) {
      //   t.name = `Выберите дану приема на работу!`

      // }
      // if (!newValue.email) {
      //   t.email = `Выберите логин!`

      // }
      // if (!newValue.inn) {
      //   t.inn = `Введите пароль!`

      // }
      // if (!newValue.bank_name) {
      //   t.bank_name = `Выберите телефон!`

      // }


      // setErr(t)
      if (

        // !t.name &&
        newValue.contragent


      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/contragentsrashod/create`,
          { date: date, prodArr: prodArr, newValue: newValue, smena: smena_id, admin: admin.id, shop_id: shop_id, avansreport_id: avansreport_id }
        )
        setScladHistoryShops([...scladHistoryShops, newProd.data.scladhistory])
        setContrAgentsPrihods([...contragentsPrihods, newProd.data.nakladnaya])
        // smenas, setSmenas,
        setSmenas(smenas.map(pr => { if (pr.shop_id == shop_id && pr.admin_id == admin.id && pr.open) { return newProd.data.smena } else { return pr } }))
        setAvansReports(avansReports.map(pr => {
          if (pr.id == newProd.data.ar.id) {
            return newProd.data.ar
          } else return pr
        }))
        const sh = scladShops.filter(oo => oo.shop_id != shop_id)
        setScladShops([...sh, ...newProd.data.shostore])
        
        // const kassa = newProd.data.kassa
        // const oldkassa = shopKassas.find(pr => pr.id == newProd.data.kassa.id)
        // if (oldkassa) {
        //   setShopKassas(shopKassas.map(pr => { if (pr.id == newProd.data.kassa.id) { return newProd.data.kassa.id } else { return pr } }))
        // } else {
        //   setShopKassas([...shopKassas, newProd.data.kassa])
        // }
        // contragents, setContrAgents
        // setContrAgents([...contragents, newProd.data])
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Накладная успешно добавлена! Приход был добавлен на скалад, в историю и в статистику продаж!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setaddCARashod(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      console.log(e)
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }
  const submit = async () => {
    confirmAlert({
      title: 'Накладная',
      message: 'Создать новую накладную?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {addCARashod && (

        <div onClick={closeModal} id="container12464" className={style.modal_container}>

          <div className={style.borders}>
            <div className={style.inputtest}>

              <label >Выберите контрагента:</label>

              <select name="pets" id="prod"
                onChange={(event) => {
                  setNeValue({ ...newValue, contragent: event.target.value });
                }}
              >
                <option value="">--Выберите контрагента--</option>
                {contragents.map((pr) => {
                  return (
                    <option value={pr.id}>{pr.name}</option>

                  )
                })}
                {/* <option value="Доставщик">Доставщик</option>
                  <option value="Кладовщик">Кладовщик</option>
                  <option value="Бариста">Бариста</option> */}

              </select>
              {err.contragent && (
                <p style={{ color: "red" }}>{err.contragent}</p>
              )}
            </div>

            <div className={style.inputtest}>

              <label >Выберите категорию поставки:</label>

              <select name="pets" id="prod"
                onChange={(event) => {
                  setNeValue({ ...newValue, kategory: event.target.value });
                }}
              >
                <option value="">--Выберите категорию--</option>

                <option value="Молочные продукты">Молочные продукты</option>
                <option value="Фрукты и овощи ">Фрукты и овощи </option>
                <option value="Выпечка и закуски">Выпечка и закуски</option>
                <option value="Канцелярские товары">Канцелярские товары</option>
                <option value="Моющие и чистящие средства">Моющие и чистящие средства</option>
                <option value="Прочее">Прочее</option>

              </select>
              {/* {err.contragent && (
  <p style={{ color: "red" }}>{err.contragent}</p>
)} */}
            </div>
            <div className={style.inputtest}>

              <label >Выберите дату накладной:</label>

              <DatePicker locale={ru}
                // includeDates={avansReports.filter(pr => selectedShop == pr.shop_id).map(f => new Date(f.createdAt))} // Restrict selectable dates
                
                selected={newValue.doc_date} onChange={(date) =>  setNeValue({ ...newValue, doc_date: date })()} />

            </div>
            <div className={style.inputtest}>

              <label >Введите номер накладной:</label>

              <input value={newValue.doc_number} type="text" id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, doc_number: event.target.value });
              }} />
              {err.nal && (
                <p style={{ color: "red" }}>{err.nal}</p>
              )}
            </div>
            {prodArr.length > 0 && (
              <div className={style.inputtest}>
                <ol>
                  {prodArr.map(pr => (
                    <li>
                      <div className={style.list}>
                        <div className={style.itemlist}>
                          {pr.name}  x  {pr.qty}{pr.mnt}
                        </div>
                        <div className={style.actionlist}>
                          <FcCancel onClick={() => dlt(pr.prod_id)} />

                        </div>
                      </div>
                    </li>
                  ))}
                </ol>

              </div>
            )}

            <div className={style.inputtest}>

              <label >Выберите товар:</label>

              <select name="pets" id="prod"
                value={newValue1.prod_id}
                onChange={(event) => {
                  setNeValue1({ ...newValue1, prod_id: event.target.value });
                }}

              >
                <option value="">--Выберите товар--</option>

                {uniqueSerials.map(serials => {

                  const table = sorted.filter(prod => prod.kategory === serials)

                  return (<>
                    <optgroup label={`${serials}`}>
                      {table.map(prod => (
                        <option value={`${prod.id}`}>{prod.name}</option>

                      ))}
                    </optgroup></>
                  )
                }
                )}


              </select>
              {err.prod_id && (
                <p style={{ color: "red" }}>{err.prod_id}</p>
              )}
              {/* </div>
            <div className={style.inputtest}> */}

              <label >Введите количество:</label>

              <input value={newValue1.qty} type="number" id="pass" name="pass" onChange={(event) => {
                setNeValue1({ ...newValue1, qty: event.target.value });
              }} />
              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}

              <Button
                text="Добавить товар"
                background="var(--primary-color)"
                color="white"
                // width="100%"
                onClick={addProd}
              />
              {err.err && (
                <p style={{ color: "red" }}>{err.err}</p>
              )}
            </div>
            {/* // */}
            <div className={style.inputtest}>

              <label >Наличный расчет:</label>

              <input value={newValue.nal} type="text" id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, nal: event.target.value });
              }} />
              {err.nal && (
                <p style={{ color: "red" }}>{err.nal}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Безналичный расчет:</label>

              <input value={newValue.beznal} type="text" id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, beznal: event.target.value });
              }} />
              {err.beznal && (
                <p style={{ color: "red" }}>{err.beznal}</p>
              )}
            </div>


            {/* <div className={style.inputtest}>

                <label >Выберите роль:</label>

                <select name="pets" id="prod"
                  onChange={(event) => {
                    setNeValue({ ...newValue, role: event.target.value });
                  }}
                >
                  <option value="">--Выберите категорию--</option>
                  <option value="Администратор">Администратор</option>
                  <option value="Доставщик">Доставщик</option>
                  <option value="Кладовщик">Кладовщик</option>
                  <option value="Бариста">Бариста</option>

                </select>
                {err.role && (
                  <p style={{ color: "red" }}>{err.role}</p>
                )}
              </div> */}



            <Button
              text="Создать"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={submit}
            />



          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default AddContrAgentsRashodModal;
