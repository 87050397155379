import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RiDiscountPercentFill } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css/animate.min.css';
import { BiSolidSave } from "react-icons/bi";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import ComfirmReportModal from "./ComfirmReportModal.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcOk, FcUp } from "react-icons/fc";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked, MdOutlineCheckCircleOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import  ru  from 'date-fns/locale/ru';
  registerLocale('ru', ru)
const AvansReportCreate = ({ shop_id, smena, CloseSmenaReturn }) => {
  const { createavansReportModal, setCreateAvansReportModal, setAdmin, setComfirmReportModal, saleReports, setSaleReports, AvansReportCreateModal, setAvansReportModal, saleReportForms, saleReportSteps, setSaleReportSteps, scladShops, reciepts, sclad, setEditShopModal, admins, setAdmins, admin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  // const closeModal = () => {
  //   setCreateAvansReportModal(false)
  // }


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportName, setReportName] = useState('');
  const [fullName, setFullName] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Логика для обработки данных формы
    console.log({
      startDate,
      endDate,
      shop_id
   
    });
    try{
const res = await $api.post(`${BACKURL}/api/avansreports/create`,{ startDate:startDate,
  endDate:endDate,
  shop_id:shop_id,
  admin_id:admin.id,
})
closeModal()
    }catch(e){
      console.log(e)
    }
  };
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "gjvvghgjhvhhv" && setCreateAvansReportModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const submit = async (func, title, message, arg) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await func(arg)
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  return (
    <>
      {/* <ReactNotifications /> */}
      {createavansReportModal && (
        <div
           onClick={closeModal}
          id="gjvvghgjhvhhv" className={style.modal_container}
        // style={{backgroundImage: "url('../../../pages/auth/unnamed-2.jpg')"}}
        >
        <div className={style.borders2} style={{width:'auto', height: 'auto' }}>
        <form onSubmit={handleSubmit} style={{display:'flex', flexDirection: 'column', alignItems:'end' }}>
      <div>
        <label>Начало периода:</label>
        <DatePicker
               locale='ru'
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          placeholderText="Выберите дату начала"
        />
      </div>

      <div>
        <label>Конец периода:</label>
        <DatePicker
               locale="ru"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd/MM/yyyy"
          placeholderText="Выберите дату окончания"
        />
      </div>

   

      <button type="submit">Сформировать новый отчет</button>
    </form>
        </div>
        </div >
      )}
      {
        loading && (
          <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )
      }

    </>
  )
}
export default AvansReportCreate;